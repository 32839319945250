import React from 'react';
import PandoraModel from "traits/PandoraModel";
import DevicesIcon from '@material-ui/icons/Devices';
import BatteryFullIcon from '@material-ui/icons/BatteryFull';
import InfoIcon from '@material-ui/icons/Info';
import * as License from "license.js";
import * as Permissions from "permission.js";
import VanButton from 'components/VanButton/VanButton';
import LocaleFromNow from 'components/LocaleFromNow/LocaleFromNow';
import { hasLicense, licenseToSourcecodeName } from "utils.js";
import { lazyRetry } from "lazyRetry";

class Tablet extends PandoraModel {
	static modelName = "Tablet";
	static getLicense() { return License.ICETRUCK}

	static icon = DevicesIcon;

	// static orderable = true;
	// static copyable = true;
	// static loadBeforeCopy = false;
	// static hasCrudPages = false;
	// static crudAsNotification = true;
	// static editableOnTable = true;
	static defaultSortBy = "name";
	static defaultSortOrder = "asc";
	static customViews = [
		{
			path: "/iceshop/tablet/",
			name: this.getLicense()+"."+this.getCollectionName_snake().toUpperCase()+".INDEX",
			icon: this.icon,
			fa_icon: this.fa_icon,
			component: lazyRetry(() => import("pandoraviews/"+licenseToSourcecodeName(this.getLicense())+"/"+this.getCollectionNamePascal()+"/"+"Index"+this.getCollectionNamePascal())),
			layout: "/admin",
			hidden: this.hideIndexRoute ?? false,
			license: License.ICESHOP,
			permissions: this.getIndexPermissions(),
		},
	];

	// PANDORA_ANCHOR_INSERT_OVERRIDE_VARIABLES_HERE

	static cards = [
		{
			title: "GENERAL_INFO",
			icon: DevicesIcon,
			fields: {
				// PANDORA_ANCHOR_INSERT_FIELDS_HERE
				name: { type: "text", maxLength: "255", required: true, showOnTable: true, md: 4, sortable: true, },
				udid: { type: "text", maxLength: "255", required: true, md: 4, },
				pin: { type: "text", maxLength: "4", md: 4, readonly: true, showOnStore: false, visible: "pin", },
				
				icecream_van: { type: "hidden", },
				delivery_van: { type: "hidden", },

				icecream_van_id: { type: "icecreamVan", md: 6, visible: (state, props) => hasLicense(props.authEmployee, License.ICETRUCK), showOnTable: (state, props) => hasLicense(props.authEmployee, License.ICETRUCK), valueOnTable: (resource) => resource.icecream_van ? <VanButton name={resource.icecream_van.name} color_background={resource.icecream_van.color_background} color_text={resource.icecream_van.color_text} /> : "", sortable: true, },
				delivery_van_id: { type: "deliveryVan", md: 6, visible: (state, props) => hasLicense(props.authEmployee, License.ICESHOP), showOnTable: (state, props) => hasLicense(props.authEmployee, License.ICESHOP), valueOnTable: (resource) => resource.delivery_van ? <VanButton name={resource.delivery_van.name} color_background={resource.delivery_van.color_background} color_text={resource.delivery_van.color_text} /> : "", sortable: true, },

				internal_note: { type: "text", maxLength: "255", required: false, },
				
				latitude: { type: "hidden", },
				longitude: { type: "hidden", },
				velocity: { type: "hidden", },
				
				icetruck_service_version_code: { type: "hidden", },
				icetruck_service_version_name: { type: "hidden", },
			},
		},
		{
			title: "BATTERY",
			icon: BatteryFullIcon,
			fields: {
				battery_level: { type: "text", maxLength: "255", readonly: true, required: false, showOnTable: true, valueOnTable: (resource) => <span>{resource.battery_level ? (resource.battery_level+"%") : ""}{(resource.battery_level && resource.battery_status) ? ", " : ""}{resource.battery_status ? (resource.battery_status) : ""}</span>, sortable: true, md: 2, },
				battery_status: { type: "text", maxLength: "255", readonly: true, required: false, md: 4, },
			},
		},
		{
			title: "EXTRA_INFO",
			icon: InfoIcon,
			fields: {
				cellphone_nr: { type: "text", maxLength: "255", required: false, md: 4, },
				"//empty_after_cellphone_nr": {type: "empty", sm: 8, },
				imei: { type: "text", maxLength: "255", required: false, md: 4, },
				sim_card_nr: { type: "text", maxLength: "255", required: false, md: 4, },
				sim_card_type: { type: "text", maxLength: "255", required: false, md: 4, },
				extra_information: { type: "text", maxLength: "255", required: false, },

				updated_at: { type: "datetime", required: false, visible: false, showOnTable: true, valueOnTable: (resource) => <LocaleFromNow value={resource.updated_at} />, sortable: true, },
			},
		},
		
		// {
		// 	title: "GENERAL_INFO",
		// 	icon: FiberNewIcon,
		// 	fields: {
		// 		// text: {type: "text", required: true, },
		// 		// textarea: {type: "text", multiline: true, rows: 12, required: true, },
		// 		// number: {type: "number", required: true, inputProps: {min: 0, max: 99999, step: 1}, },
		// 		// select: {type: "select", required: true, options: ["OPTION"], },
		// 		// checkbox: {type: "checkbox", },
				
		// 		// md12: {type: "text", md: 12, },
		// 		// showOnTable: {type: "text", showOnTable: true, },
		// 	},
		// },

	];

	// PANDORA_ANCHOR_INSERT_OVERRIDE_FUNCTIONS_HERE
	// static getCopyableChildren() { return [TabletItem]; };
	static getIndexPermissions() {
		return [
			Permissions.ICETRUCK_TABLETS_VIEW, Permissions.ICETRUCK_TABLETS_MODERATE, 
			Permissions.ICESHOP_TABLETS_VIEW, Permissions.ICESHOP_TABLETS_MODERATE, 
		];
	}

	static getShowPermissions() {
		return [
			Permissions.ICETRUCK_TABLETS_VIEW, Permissions.ICETRUCK_TABLETS_MODERATE, 
			Permissions.ICESHOP_TABLETS_VIEW, Permissions.ICESHOP_TABLETS_MODERATE, 
		];
	}

	static getUpdatePermissions() {
		return [
			Permissions.ICETRUCK_TABLETS_MODERATE, 
			Permissions.ICESHOP_TABLETS_MODERATE, 
		];
	}

	static getStorePermissions() {
		return [
			Permissions.ICETRUCK_TABLETS_MODERATE, 
			Permissions.ICESHOP_TABLETS_MODERATE, 
		];
	}

	static getDestroyPermissions() {
		return [
			Permissions.ICETRUCK_TABLETS_MODERATE, 
			Permissions.ICESHOP_TABLETS_MODERATE, 
		];
	}
}

export default Tablet;